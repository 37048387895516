import cx from 'classnames';
import { createImageSizes, createImageSrcSet } from '@hubcms/utils-images';
import type { ImageFormat, ImageSizes } from '@hubcms/domain-images';
import { isTeaserImage, type TeaserImage } from '@hubcms/domain-teaser';

import styles from './native-teaser-image.module.scss';
import { getImageDetails } from '../../utils/getImageDetails';

type Props = {
  image: TeaserImage | string;
  format: ImageFormat;
  isPriority?: boolean;
  sizes?: ImageSizes;
  className?: string;
};

export function NativeTeaserSimpleImage({ image, format, sizes, isPriority = false, className }: Props) {
  if (isTeaserImage(image)) {
    const { url: defaultSrc, caption: defaultCaption, aspectRatio } = getImageDetails(image, format);
    return (
      <img
        src={defaultSrc}
        alt={defaultCaption}
        className={cx(className, styles.nativeTeaserImage, aspectRatio)}
        srcSet={createImageSrcSet(defaultSrc)}
        sizes={createImageSizes(sizes)}
        loading={isPriority ? 'eager' : 'lazy'}
      />
    );
  }
  return (
    <img
      src={image}
      alt={image.split('/').pop() ?? ''}
      className={cx(className, styles.nativeTeaserImage, format)}
      sizes={createImageSizes(sizes)}
      loading={isPriority ? 'eager' : 'lazy'}
    />
  );
}
