import { Caption } from '@mediahuis/chameleon-react';
import cx from 'classnames';
import { PropsWithChildren } from 'react';

import styles from './duration-indicator.module.scss';

type DurationIndicatorProps = PropsWithChildren<{
  className?: string;
}>;

export default function DurationIndicator({ children, className }: DurationIndicatorProps) {
  return (
    <Caption fontWeight="regular" className={cx(styles.durationIndicator, className)}>
      {children}
    </Caption>
  );
}
