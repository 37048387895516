import { FC } from 'react';

import { TeaserImage } from '@hubcms/domain-teaser';
import { PerViewport, Viewport, viewPorts } from '@hubcms/domain-styling';
import { ImageFormat, ImageSizes } from '@hubcms/domain-images';
import { error } from '@hubcms/utils-monitoring';
import { createImageSrcSet } from '@hubcms/utils-images';
import {
  breakpointXsMax,
  breakpointSmMin,
  breakpointMdMin,
  breakpointLgMin,
  breakpointLgMax,
} from '@mediahuis/chameleon-theme-wl/tokens';

import { NativeTeaserSimpleImage } from './NativeTeaserSimpleImage';
import { getImageDetails } from '../../utils/getImageDetails';

const breakpoints: Record<Viewport, string> = {
  xs: breakpointXsMax,
  sm: breakpointSmMin,
  md: breakpointMdMin,
  lg: breakpointLgMin,
  xl: breakpointLgMax,
};

type NativeTeaserImageProps = {
  image: TeaserImage;
  format: ImageFormat | PerViewport<ImageFormat>;
  sizes?: ImageSizes;
  isPriority?: boolean;
  className?: string;
};

const NativeTeaserImage: FC<NativeTeaserImageProps> = ({ image, sizes, format, className, isPriority = false }) => {
  if (typeof format === 'object') {
    if (Object.keys(format).length === 0) {
      error(`An image format object without any keys was passed for image with caption ${image.caption}`);
      return null;
    }
    const formatsSortedByViewPort = viewPorts
      .map(viewPort => format[viewPort])
      .filter((formatForViewPort): formatForViewPort is ImageFormat => formatForViewPort !== undefined);
    const smallestViewPort = viewPorts.find(viewPort => Object.hasOwn(format, viewPort));

    const sourceViewPorts = viewPorts
      .filter(viewPort => viewPort !== smallestViewPort)
      .filter(viewPort => Object.hasOwn(format, viewPort))
      .reverse();

    const smallestViewportFormat = formatsSortedByViewPort[0];

    // Order largest view ports first - provide fallback image for smallest view port
    return (
      <picture>
        {sourceViewPorts.map(viewPort => {
          // we have already checked above for format to contain "viewPort"
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const { url } = getImageDetails(image, format[viewPort]!);
          return <source key={viewPort} srcSet={createImageSrcSet(url)} media={`(min-width: ${breakpoints[viewPort]})`} />;
        })}
        <NativeTeaserSimpleImage format={smallestViewportFormat} image={image} isPriority={isPriority} className={className} />
      </picture>
    );
  }

  return <NativeTeaserSimpleImage format={format} image={image} isPriority={isPriority} sizes={sizes} className={className} />;
};

export default NativeTeaserImage;
