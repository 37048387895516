import { ImageFormat } from '@hubcms/domain-images';

export type TeaserImageSource = {
  width: number;
  height: number;
  url: string;
};

export type TeaserImage = {
  caption: string | null;
  imageFormats: Record<Exclude<ImageFormat, 'baseWidth'>, TeaserImageSource>;
};

export function isTeaserImage(image: TeaserImage | string): image is TeaserImage {
  return typeof image !== 'string';
}
